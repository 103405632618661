<!--
  * @Author: 黎加冬
  * @Date: 2020-08-10
 * @LastEditTime: 2024-03-13 19:50:48
 * @LastEditors: Please set LastEditors
  * @Description: 分析建模-汇总报表
-->
<template>
  <enReportAnalysisTotalList :templateId="templateId" :isPreview="isPreview" :type="type" :shouldGetCondition="shouldGetCondition"></enReportAnalysisTotalList>
</template>
<script>
export default {
  props: {
    templateId: {
      type: String,
      default: ""
    },
    isPreview: {
      type: String,
      default: ""
    },
    type: {
      type: Number,
      default: 1
    },
    // 是否需要获取refConditions
    shouldGetCondition: {
      type: [Boolean, String],
      default: false
    }
  }
};
</script>

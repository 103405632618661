var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("enReportAnalysisTotalDetail", {
    attrs: {
      templateId: _vm.templateId,
      isPreview: _vm.isPreview,
      sourceId: _vm.sourceId,
      dataType: _vm.dataType,
      reportType: _vm.reportType,
      type: _vm.type,
      isQueryList: _vm.isQueryList,
      shouldGetCondition: _vm.shouldGetCondition,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<!--
  * @Author: czm
  * @Date: 2021-1-18
 * @LastEditTime: 2024-03-14 09:38:32
 * @LastEditors: Please set LastEditors
  * @Description: 分析建模-自定义报表
-->
<template>
  <enReportAnalysisCustomReport :templateId="templateId" :isPreview="isPreview" :type="type" :isNewFlag="isNewFlag" :shouldGetCondition="shouldGetCondition"></enReportAnalysisCustomReport>
</template>
<script>
export default {
  props: {
    templateId: {
      type: String,
      default: ""
    },
    isPreview: {
      type: String,
      default: ""
    },
    type: {
      type: Number,
      default: 1
    },
    isNewFlag: {
      type: [String, Number]
    },
    // 是否需要获取refConditions
    shouldGetCondition: {
      type: [Boolean, String],
      default: false
    }
  }
};
</script>

<!--
  * @Author: czm
  * @Date: 2020-10-28
 * @LastEditTime: 2024-03-14 10:55:12
 * @LastEditors: Please set LastEditors
  * @Description: 分析建模-明细报表
-->
<template>
  <enReportAnalysisTotalDetail
    :templateId="templateId"
    :isPreview="isPreview"
    :sourceId="sourceId"
    :dataType="dataType"
    :reportType="reportType"
    :type="type"
    :isQueryList="isQueryList"
    :shouldGetCondition="shouldGetCondition"
  ></enReportAnalysisTotalDetail>
</template>
<script>
export default {
  props: {
    templateId: {
      type: String,
      default: ""
    },
    isPreview: {
      type: String,
      default: ""
    },
    sourceId: {
      type: String,
      default: ""
    },
    dataType: {
      type: String,
      default: ""
    },
    reportType: {
      type: String,
      default: ""
    },
    type: {
      type: Number,
      default: 1
    },
    // refConditions: {
    //   type: [Array, String],
    //   default: () => []
    // },
    // 是否只需查询列表
    isQueryList: {
      type: [Boolean, String],
      default: false
    },
    // 是否需要获取refConditions
    shouldGetCondition: {
      type: [Boolean, String],
      default: false
    }
  }
};
</script>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("enReportAnalysisCustomReport", {
    attrs: {
      templateId: _vm.templateId,
      isPreview: _vm.isPreview,
      type: _vm.type,
      isNewFlag: _vm.isNewFlag,
      shouldGetCondition: _vm.shouldGetCondition,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }